<!--
  PACKAGE_NAME : src\pages\esp\auth\modal-add-auth.vue
  FILE_NAME : modal-add-auth
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 권한 추가
-->
<template>
  <div>
    <table class="table_form line-bin">
      <caption>
        <strong>권한 추가</strong>
      </caption>
      <colgroup>
        <col style="width: 150px" />
        <col style="width: auto" />
      </colgroup>

      <tbody>
        <tr>
          <th scope="row">
            <label>권한명 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <DxTextBox
              stylingMode="outlined"
              class="alB"
              :width="325"
              placeholder="입력해주세요."
              v-model="formData.authNm"
              :max-length="config.limitLength.authNm"
            >
              <DxValidator validation-group="modalValidationGroup">
                <DxRequiredRule message="권한명을 입력해주세요." />
              </DxValidator>
            </DxTextBox>
          </td>
        </tr>
        <tr v-if="formData.parentIdList.length > 0">
          <th scope="row">
            <label>상위권한 <span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <div>
              <DxDropDownBox
                placeholder="선택"
                class="check-type"
                display-expr="authNm"
                value-expr="id"
                :width="325"
                :show-clear-button="true"
                :items="formData.parentIdList"
                :styling-mode="config.stylingMode"
                v-model="formData.parentId"
                :opened="isTreeBoxOpened"
                @opened="onOpened"
              >
                <DxValidator validation-group="modalValidationGroup" ref="parentIdValidator">
                  <DxRequiredRule message="상위권한을 선택해주세요." />
                </DxValidator>

                <template #content="{}">
                  <DxTreeView
                    :data-source="formData.parentIdList"
                    data-structure="plain"
                    key-expr="id"
                    display-expr="authNm"
                    parent-id-expr="parentId"
                    selection-mode="single"
                    :expand-all-enabled="true"
                    :select-by-click="true"
                    @item-selection-changed="onChangedAuth"
                  />
                </template>
              </DxDropDownBox>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import DxTreeView from 'devextreme-vue/tree-view';

  export default {
    components: {
      DxTreeView,
      DxDropDownBox,
      DxRequiredRule,
      DxValidator,
      DxSelectBox,
      DxTextBox,
    },
    props: {
      modalData: Object,
    },
    watch: {},
    data() {
      return {
        config: {
          api: {
            add: 'AUTH_LIST_REGISTER',
          },
          limitLength: {
            authNm: 20,
          },
        },
        formData: {
          authNm: '',
          parentIdList: [],
          parentId: null,
        },
        isTreeBoxOpened: false,
      };
    },
    computed: {},
    methods: {
      createdData() {
        this.formData.parentIdList = JSON.parse(JSON.stringify(this.modalData.list));
        this.formData.parentId = this.modalData.rowData.id;
      },
      mountedData() {
        //데이터 저장
        this.$_eventbus.$on('ModalAddAuth:onSaveData', async (e, resolve) => {
          if (!e.validationGroup.validate().isValid) {
            if (this.formData.authNm == null || this.formData.authNm === '') {
              this.$_Toast('권한명을 입력해주세요.');
              return false;
            }

            if (this.formData.parentId == null || this.formData.parentId === '') {
              this.$_Toast('상위권한을 선택해주세요.');
              return false;
            }

            return false;
          }

          let data = {
            parentId: this.formData.parentId,
            authNm: this.formData.authNm,
            regId: this.$store.getters.getLoginId,
            res: 200,
          };
          resolve(data);
          this.beforeDestroyedData();
        });
      },
      /** @description : 이벤트 버스 off
       */
      beforeDestroyedData() {
        this.$_eventbus.$off('ModalAddAuth:onSaveData');
      },
      /** @description : 메뉴권한 selectbox 오픈 이벤트
       */
      onOpened() {
        this.isTreeBoxOpened = true;
      },
      /** @description : 권한 selectbox 변경 이벤트
       */
      onChangedAuth(e) {
        if (e.itemData) {
          this.formData.parentId = e.itemData.id;
          this.isTreeBoxOpened = false;
        }
      },
    },
    async created() {
      await this.createdData();
    },
    beforeMount() {
      this.beforeDestroyedData();
    },
    mounted() {
      this.mountedData();
    },
    beforeDestroyed() {
      this.beforeDestroyedData();
    },
  };
</script>

<style scoped></style>
